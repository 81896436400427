import React,{Component,Suspense,lazy, useEffect} from 'react';
import ReactPixel from 'react-facebook-pixel';
import { connect } from "react-redux";
import {  Route, Router, BrowserRouter, Switch, withRouter, useHistory} from 'react-router-dom';
import ReactGA from 'react-ga';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import AWS from 'aws-sdk';
import TagManager from 'react-gtm-module';
import { history } from './helpers/history';

//import "video-react/dist/video-react.css";

const Index =lazy(() =>import ('./components/Index'));
const IndexDup =lazy(() =>import ('./components/IndexDup'));
const Aboutus =lazy(() =>import ('./components/Aboutus'));
const AboutusMob =lazy(() =>import ('./components/AboutusMob'));
const TermsCondition =lazy(() =>import ('./components/TermsCondition'));
const TermsandConditions =lazy(() =>import ('./components/TermsandConditions'));
const PrivacyPolicy =lazy(() =>import ('./components/PrivacyPolicy'));
const Faqs =lazy(() =>import ('./components/Faqs'));
const TermsOfuse =lazy(() =>import ('./components/TermsOfuse'));
const Privacy_Policy =lazy(() =>import ('./components/Privacy_Policy'));
const Faq =lazy(() =>import ('./components/Faq'));
const HowItWorks =lazy(() =>import ('./components/HowItWorks'));
const AboutPartners =lazy(() =>import ('./components/AboutPartners'));
const AboutPartnersMob =lazy(() =>import ('./components/AboutPartnersMob'));
const aboutTeam =lazy(() =>import ('./components/aboutTeam'));
const aboutTeamMob =lazy(() =>import ('./components/aboutTeamMob'));
const PostInfo =lazy(() =>import ('./components/PostInfoPage'));
const DonationCampaign=lazy(() =>import ('./components/DonateCampaign'));


const NgoRegProcOne =lazy(() =>import ('./components/ngo/NgoRegProcOne'));
const NgoRegProcTwo =lazy(() =>import ('./components/ngo/NgoRegProcTwo'));
const NgoRegProcThree =lazy(() =>import ('./components/ngo/NgoRegProcThree'));
const NgoRegProcFour =lazy(() =>import ('./components/ngo/NgoRegProcFour'));
const NgoRegProcThankYou =lazy(() =>import ('./components/ngo/NgoRegProcThankYou'));
const NgoRegProcOneUpdate =lazy(() =>import ('./components/ngo/NgoRegProcOneUpdate'));
const NgoRegProcTwoUpdate =lazy(() =>import ('./components/ngo/NgoRegProcTwoUpdate'));
const NgoRegProcThreeUpdate =lazy(() =>import ('./components/ngo/NgoRegProcThreeUpdate'));
const NgoRegProcFourUpdate =lazy(() =>import ('./components/ngo/NgoRegProcFourUpdate'));
const NgoRegProcFourUpdateEdit =lazy(() =>import ('./components/ngo/NgoRegProcFourUpdateEdit'));
const NgoRegProgThankYou =lazy(() =>import ('./components/ngo/NgoRegProgressThankYou'));

const NgoHome =lazy(() =>import ('./components/ngo/NgoHome'));
const UserHomeDup =lazy(() =>import ('./components/user/UserHomeDup'));
const UserHome =lazy(() =>import ('./components/user/UserHome'));
const UserProfile =lazy(() =>import ('./components/user/UserProfile'));
const UserUserProfile =lazy(() =>import ('./components/user/UserUserProfile'));
const UserUserProfile_withoutlogin =lazy(() =>import ('./components/UserUserProfile_beforelogin'));
const UserNgoList =lazy(() =>import ('./components/user/UserNgoList'));
const UserList =lazy(() =>import ('./components/user/UserList'));
const UserProfile1 =lazy(() =>import ('./components/user/UserProfile1'));

const UserNgoReview =lazy(() =>import ('./components/user/UserNgoReview'));
const UserNgoInfo =lazy(() =>import ('./components/user/UserNgoInfo'));
const NgoReviewList =lazy(() =>import ('./components/ngo/NgoReviewList'));
const NgoAllValunteer =lazy(() =>import ('./components/ngo/NgoAllValunteer'));

const NgoProfile =lazy(() =>import ('./components/ngo/NgoProfilePage'));
const NgoProfilewithOutLogin =lazy(() =>import ('./components/NgoProfilePage_beforelogin'));

const NgoAboutProfile =lazy(() =>import ('./components/ngo/NgoAboutProfile'));
const NgoOrganization =lazy(() =>import ('./components/ngo/NgoOrganization'));
const NgoDonation =lazy(() =>import ('./components/ngo/NgoDonation'));

const NgoNotification =lazy(() =>import ('./components/ngo/NgoNotification'));
const NgoAboutProfile1 =lazy(() =>import ('./components/ngo/NgoAboutProfile1'));

const UserDonate =lazy(() =>import ('./components/user/UserDonate'));
const UserValunteer =lazy(() =>import ('./components/user/UserValunteer'));
const UserAboutProfile =lazy(() =>import ('./components/user/UserAboutProfile'));

const UserMyCampaign =lazy(() =>import ('./components/user/UserMyCampaign'));
const UserMyDonations =lazy(() =>import ('./components/user/UserMyDonations'));

const Volunteer =lazy(() =>import ('./components/Valunteer'));
const Donate =lazy(() =>import ('./components/Donate'));
//const NgoList =lazy(() =>import ('./components/NgoList_adani'));
const NgoList =lazy(() =>import ('./components/NgoList'));

const UserNgoProfile =lazy(() =>import ('./components/user/NgoProfilePage'));
const UserNgoAboutProfile =lazy(() =>import ('./components/user/NgoAboutProfile'));

//const NgoProfile_edit =lazy(() =>import ('./components/user/NgoAboutProfile_edit'));

const UserNotification =lazy(() =>import ('./components/user/UserNotification'));

const ConfirmEmail =lazy(() =>import ('./components/ConfirmEmail'));
const donate_firstpage =lazy(() =>import ('./components/DonateFirstPage'));
const donate_secondpage =lazy(() =>import ('./components/DonateSecondPage'));




const ProductAdd =lazy(() =>import ('./components/marketplace/ProductAdd'));
const NgoProductList =lazy(() =>import ('./components/marketplace/NgoProductList'));
const NgoOrderList =lazy(() =>import ('./components/marketplace/NgoOrderList'));

const UserAddressList =lazy(() =>import ('./components/marketplace/UserAddressList'));
const UserAddressAdd =lazy(() =>import ('./components/marketplace/UserAddressAdd'));
const UserAddressInfo =lazy(() =>import ('./components/marketplace/UserAddressInfo'));

const UserProductList =lazy(() =>import ('./components/marketplace/UserProductList'));

const UserFavouriteProductList =lazy(() =>import ('./components/marketplace/UserFavouriteProductList'));
const UserProductInfo =lazy(() =>import ('./components/marketplace/UserProductInfo'));
const UserCart =lazy(() =>import ('./components/marketplace/UserCart'));
const UserPlaceOrder =lazy(() =>import ('./components/marketplace/UserPlaceOrder'));
const UserPlaceOrderPayment =lazy(() =>import ('./components/marketplace/UserPlaceOrderPayment'));

const UserOrderList =lazy(() =>import ('./components/marketplace/UserOrderList'));
const DaanUtsav =lazy(() =>import ('./components/DaanUtsavNew'));

const showngo =lazy(() =>import ('./components/ShowNgo'));

const ProductInfo =lazy(() =>import ('./components/marketplace/ProductInfo'));
const ProductList =lazy(() =>import ('./components/marketplace/ProductList'));

const UserMyRecurrings=lazy(() =>import('./components/user/UserMyRecurrings'));

const marketplace_comingsoon =lazy(() =>import ('./components/MarketPlaceSoon'));

const AboutusSecretSanta =lazy(() =>import ('./components/SecretSanta'));
const Youngbees =lazy(() =>import ('./components/Youngbees'));

const Youngbees_web =lazy(() =>import ('./components/Navbar'));
//const Youngbees_mobile =lazy(() =>import ('./components/Youngbees_Mobile'));
const Youngbees_mobile =lazy(() =>import ('./components/MobAppYng'));

const donate_marketpage =lazy(() =>import ('./components/DonateMarketplacePage'));

const donate_recurring_page =lazy(() =>import ('./components/Donate_recurring_detailspage'));

const NgoMarketPlaceThree =lazy(() =>import ('./components/ngo/ngoMarketplacethree'))
const ngoMarketOne =lazy(() =>import ('./components/ngo/ngomarketplacesone'))

const NotFound =lazy(() =>import ('./components/NotFound'));


const corporate =lazy(() =>import ('./components/corporate'));
const corporate_mobile=lazy(() =>import ('./components/corporate_mobile'));

// const corporate =lazy(() =>import ('./components/corporate_adani'));
// const corporate_mobile=lazy(() =>import ('./components/corporate_mobile'));

const new_youngbees =lazy(() =>import ('./components/new_youngbees'));
const new_youngbees_mobile=lazy(() =>import ('./components/new_youngbees_mobile'));
// for explore school
const School=lazy(()=>import('./components/user/Schools'))
const UserSchoolList=lazy(()=>import('./components/user/UserSchoolList'))
const Schoolinfobeforelogin =lazy(() =>import ('./components/Schoolinfobeforelogin'));
const UserSchoolinfo =lazy(() =>import ('./components/user/userSchoolinfo'));
const NewHome =lazy(() =>import ('./components/user/newhome_about'));
const Christmas =lazy(() =>import ('./components/ChristmasSanta'));

// const Chennai_Marathon =lazy(() =>import ('./components/NgoList_Marathon'));
// const User_Chennai_Marathon =lazy(() =>import ('./components/user/UserNgoList_Marathon'));

 const NgoList_Tcsw =lazy(() =>import ('./components/NgoList_Tcsw'));

const AdaniHome =lazy(() =>import ('./components/user/Adanihome_about'));

const KQPage =lazy(() =>import ('./components/KQPage'));

function App (){  
  let history = useHistory();
  console.log('history', history)
  useEffect(() => {

    

    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
    ReactGA.pageview(window.location.pathname + window.location.search);
      return history.listen((location, action) => {
       console.log(`You changed the page to: ${location.pathname}`)
       ReactGA.pageview(location.pathname + location.search);

       if (action === 'POP' && location.pathname === '/kq-page') {
        history.push('/test'); // Redirect to home page or any other desired page
      }
    });

    

 },[history]) 

AWS.config.update({
  	accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY,
  	secretAccessKey: process.env.REACT_APP_AWS_SECRETKEY
 });

 //const advancedMatching = { em: 'some@email.com' };
 const options = {
   autoConfig: true,
   debug: false,
 };

 ReactPixel.init(process.env.REACT_APP_FACEBOOK_CODE, options);

 ReactPixel.pageView();


const tagManagerArgs = {
  gtmId:  process.env.REACT_APP_GTM_KEY
}

TagManager.initialize(tagManagerArgs);


 /*window.dataLayer.push({
    event: 'pageview'
  });*/
window.dataLayer.push({
  event: 'pageview',
  page: {
    //url: location,
   // title: title
  }
});
return (

          <Suspense fallback={<div></div>}>
            <Switch>
              
               <Route exact path="/" component={NewHome} /> 
              {/* <Route exact path="/" component={AdaniHome} /> */}
             {/*  <Route exact path="/newhome" component={NewHome} /> */}
               <Route exact path="/index" component={Index} /> 
              <Route exact path="/new" component={UserHomeDup} />
               {/* <Route exact path="/" component={NewHome} />  */}
              <Route exact path="/newhome" component={UserHome} />
               <Route exact path="/user/home" component={NewHome} /> 
              {/* <Route exact path="/user/home" component={AdaniHome} /> */}
              <Route exact path="/volunteer" component={Volunteer} />
              <Route exact path="/donate" component={Donate} />
              <Route exact path="/ngolist" component={NgoList} />
  
              <Route exact path="/post/info/:post_id" component={PostInfo} />
  
              
              <Route exact path="/daan-utsav" component={DaanUtsav} />
             

      
              <Route exact path="/ngo" component={showngo} />
              <Route exact path="/aboutusmob" component={AboutusMob} />
              {/* Websites */}<Route exact path="/aboutus" component={Aboutus} />
              <Route exact path="/termscondition" component={TermsCondition} />
{/* Mobile */}
                 <Route exact path="/termsandconditions" component={TermsandConditions} />
              <Route exact path="/privacypolicy" component={PrivacyPolicy} />
              <Route exact path="/faqs" component={Faqs} />

{/* Websites */}
              <Route exact path="/termsofuse" component={TermsOfuse} />
              <Route exact path="/privacy_policy" component={Privacy_Policy} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/howitworks" component={HowItWorks} />

              <Route exact path="/aboutpartners" component={AboutPartners} />
              <Route exact path="/aboutpartnersmob" component={AboutPartnersMob} />
              <Route exact path="/aboutteam" component={aboutTeam} />
              <Route exact path="/aboutteammob" component={aboutTeamMob} />
              <Route exact path="/donate-for-change" component={DonationCampaign} />
  
              
              <Route exact path="/ngo/registration/one" component={NgoRegProcOne} />
              <Route exact path="/ngo/registration/two" component={NgoRegProcTwo} />
              <Route exact path="/ngo/registration/three" component={NgoRegProcThree} />
              <Route exact path="/ngo/registration/four" component={NgoRegProcFour} />
              <Route exact path="/ngo/registration/thankyou" component={NgoRegProcThankYou} />
        
  			 <Route exact path="/ngo/registration/updateone/:ngo_id" component={NgoRegProcOneUpdate} />
              <Route exact path="/ngo/registration/updatetwo/:ngo_id" component={NgoRegProcTwoUpdate} />
              <Route exact path="/ngo/registration/updatethree/:ngo_id" component={NgoRegProcThreeUpdate} />
              {/*<Route exact path="/ngo/registration/updatefour/:ngo_id" component={NgoRegProcFourUpdate} /> */}
              <Route exact path="/ngo/registration/updatefour/:ngo_id" component={NgoRegProcFourUpdateEdit} />
              <Route exact path="/ngo/progress" component={NgoRegProgThankYou} />

              <Route exact path="/ngo/home" component={NgoHome} />
              <Route exact path="/ngo/reviewlist" component={NgoReviewList} />
              <Route exact path="/ngo/ngoallvalunteer" component={NgoAllValunteer} />
              
              <Route exact path="/ngo/profile" component={NgoProfile} />
             <Route exact path="/ngolist/profile/:ngo_id/:ngo_name" component={NgoProfilewithOutLogin} />
             
              <Route exact path="/ngo/about_profile" component={NgoAboutProfile} />
              <Route exact path="/ngo/organisational_details" component={NgoOrganization} />
             
              <Route exact path="/ngo/ngoAboutProfile1" component={ NgoAboutProfile1} /> 
              <Route exact path="/ngo/donations" component={NgoDonation} />
              
              <Route exact path="/ngo/notification" component={NgoNotification} />
  
              
              {/* <Route exact path="/user/home" component={UserHome} /> */}
              <Route exact path="/user/profile" component={UserProfile} />
              <Route exact path="/user/info/:user_id" component={UserUserProfile} />
              <Route exact path="/info/:user_id/:user_name" component={UserUserProfile_withoutlogin} />
              <Route exact path="/user/ngo/:list" component={UserNgoList} />
              <Route exact path="/user/ngo/info/:ngo_id" component={UserNgoInfo} />
              <Route exact path="/user/user/list" component={UserList} />
              
              <Route exact path="/user/ngo/review/:ngo_id" component={UserNgoReview} />
              <Route exact path="/user/donate" component={UserDonate} />
              <Route exact path="/user/volunteer" component={UserValunteer} />
              <Route exact path="/user/about_profile" component={UserAboutProfile} />
              <Route exact path="/user/user_my_campaign" component={UserMyCampaign} />
              <Route exact path="/user/user_my_donations" component={UserMyDonations} />
              <Route exact path="/user/ngo/profile/:ngo_id" component={UserNgoProfile} />
              <Route exact path="/user/ngo/about_profile/:ngo_id" component={UserNgoAboutProfile} />

              <Route exact path="/user/notification" component={UserNotification} />
              <Route exact path="/user/userprofile1" component={UserProfile1} />
  
              {/* <Route exact path="/user/NgoProfile" component={NgoProfile_edit} /> */}

              <Route exact path="/confirmmail/:cid" component={ConfirmEmail} />
              <Route exact path="/user/donate_firstpage/:user_id/:ngo_id/:post_id/:token/:ngo_name" component={donate_firstpage} />
               <Route exact path="/user/donate_secondpage/:user_id/:ngo_id/:post_id/:eightyG/:recurring/:rec_id/:amt/:token" component={donate_secondpage} />
            

            <Route exact path="/ngo/product/add" component={ProductAdd} />
            <Route exact path="/ngo/product/list" component={NgoProductList} />
            <Route exact path="/ngo/order/list" component={NgoOrderList} />

            <Route exact path="/user/address/list" component={UserAddressList} />
            <Route exact path="/user/address/add" component={UserAddressAdd} />
            <Route exact path="/user/address/info" component={UserAddressInfo} />
            <Route exact path="/user/product/list" component={UserProductList} />
            <Route exact path="/user/product/favourite/list" component={UserFavouriteProductList} />
            <Route exact path="/user/product/info/:product_id" component={UserProductInfo} />
            <Route exact path="/user/cart" component={UserCart} />
            <Route exact path="/user/order/place" component={UserPlaceOrder} />
            <Route exact path="/user/order/payment/:order_id" component={UserPlaceOrderPayment} />
            <Route exact path="/user/order/list" component={UserOrderList} />

            <Route exact path="/product/list" component={ProductList} />
            <Route exact path="/product/info/:product_id" component={ProductInfo} />

            <Route exact path="/user/user_my_recurrings" component={UserMyRecurrings} />

            <Route exact path="/marketplace" component={marketplace_comingsoon} />

            {/* <Route exact path="/in-kind-donation-secret-santa" component={AboutusSecretSanta} /> */}
           
           <Route exact path="/youngbees_program" component={Youngbees_web} />

            <Route exact path="/youngbees_programw" component={Youngbees_web} />
            <Route exact path="/youngbees_programm" component={Youngbees_mobile} />

           
           
            <Route exact path="/user/donate_marketplace/:user_id/:order_id/:amnt" component={donate_marketpage} />

  <Route exact path="/ngo/marketPlace/one" component={ngoMarketOne} />
            <Route exact path="/ngo/marketplace/three" component={NgoMarketPlaceThree} />
            <Route exact path="/Corporate_programmes" component={corporate} />
            <Route exact path="/Corporate_programmesM" component={corporate_mobile} />
             <Route exact path="/School_Programmes" component={new_youngbees} /> 
             <Route exact path="/School_ProgrammesM" component={new_youngbees_mobile} /> 
       <Route exact path="/user/donate_recurringpage/:user_id/:ngo_id/:post_id/:eightyG/:recurring/:amt/:token" component={donate_recurring_page} />
        {/* for school */}
       <Route exact path="/Schoolinfo/:user_id/:user_name" component={Schoolinfobeforelogin} />
       <Route exact path="/userschool/info/:user_id" component={UserSchoolinfo} />
       <Route exact path="/school" component={School} />
       <Route exact path="/user/schoollist" component={UserSchoolList} />
       {/* <Route exact path="/in-kind-donation-santa" component={Christmas} /> */}
       <Route exact path="/in-kind-donation-secret-santa" component={Christmas} />

       {/* <Route exact path="/chennai-marathon" component={Chennai_Marathon} />
       <Route exact path="/user/chennai-marathon" component={User_Chennai_Marathon} /> */}
       
       <Route exact path="/ngolist/tcsw" component={NgoList_Tcsw} />
       

       <Route exact path="/kq-page" component={KQPage} />
            //404
            <Route component={NotFound} />
            
  
            </Switch>
          </Suspense>
        
      );

}


function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

const connectAppcomp = connect(mapStateToProps)(App);

export default connectAppcomp
